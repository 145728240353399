import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ebaccfc4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "download-excel-container" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DatePicker = _resolveComponent("DatePicker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.$t("date.from")) + " ", 1),
    _createVNode(_component_DatePicker, {
      value: $setup.formattedStartDate,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (($setup.formattedStartDate) = $event)),
      style: {"width":"15%"},
      format: $setup.dateFormat,
      "min-date": _ctx.today,
      onChange: $setup.handleStartDateChange
    }, null, 8, ["value", "format", "min-date", "onChange"]),
    _createTextVNode(" " + _toDisplayString(_ctx.$t("date.to")) + " ", 1),
    _createVNode(_component_DatePicker, {
      value: $setup.formattedEndDate,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (($setup.formattedEndDate) = $event)),
      style: {"width":"15%"},
      format: $setup.dateFormat,
      "min-date": $setup.formattedStartDate,
      onChange: $setup.handleEndDateChange
    }, null, 8, ["value", "format", "min-date", "onChange"]),
    _createElementVNode("button", {
      class: "btn btn-primary",
      disabled: !$setup.areDatesValid || !$setup.areDatesFilled,
      onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => ($setup.downloadExcel && $setup.downloadExcel(...args)), ["prevent"]))
    }, _toDisplayString(_ctx.$t("shared.download-excel")), 9, _hoisted_2)
  ]))
}

import { computed, ref } from "vue";
import DatePicker from "@/components/Form/DatePicker.vue";
import { Student } from "@/interfaces/api";
import StudentService from "@/services/student-service";
import { useI18n } from "vue-i18n";
import dayjs from "dayjs";

export default {
  name: "SocialSecurityNumber",
  components: {
    DatePicker,
  },
  setup(): unknown {
    const { t } = useI18n();
    const person = ref<Student | undefined>(undefined);
    const day = ref<string>("");
    const feedbackMessage = ref<string>("");
    const startDate = ref<string | null>(null);
    const endDate = ref<string | null>(null);
    const dateFormat = ref<string>("yyyy-MM-dd");

    const downloadExcel = async () => {
      try {
        const formattedStartDate = dayjs(startDate.value).format("YYYY-MM-DD");
        const formattedEndDate = dayjs(endDate.value).format("YYYY-MM-DD");
        const response = await StudentService.exportStudentsToExcel(
          formattedStartDate,
          formattedEndDate
        );

        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "students.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          window.URL.revokeObjectURL(url);
        } else {
          const errorMessage = await response.text();
          feedbackMessage.value = `Error downloading Excel: ${errorMessage}`;
        }
      } catch (error) {
        feedbackMessage.value = "Error downloading Excel: " + error;
      }
    };

    const handleStartDateChange = (event: Event) => {
      const input = event.target as HTMLInputElement;
      startDate.value = input.value;
    };

    const handleEndDateChange = (event: Event) => {
      const input = event.target as HTMLInputElement;
      endDate.value = input.value;
    };

    const areDatesValid = computed(() => {
      if (!startDate.value || !endDate.value) return true;
      return (
        dayjs(startDate.value).isBefore(endDate.value) ||
        dayjs(startDate.value).isSame(endDate.value)
      );
    });

    const areDatesFilled = computed(() => {
      return startDate.value && endDate.value;
    });

    const formattedStartDate = computed<string | null>({
      get() {
        return startDate.value;
      },
      set(value: string | null) {
        startDate.value = value;
      },
    });

    const formattedEndDate = computed({
      get() {
        return dayjs(endDate.value).format("YYYY-MM-DD");
      },
      set(value: string) {
        endDate.value = value;
      },
    });

    return {
      t,
      person,
      day,
      feedbackMessage,
      startDate,
      endDate,
      dateFormat,
      areDatesValid,
      areDatesFilled,
      downloadExcel,
      handleStartDateChange,
      handleEndDateChange,
      formattedStartDate,
      formattedEndDate,
    };
  },
};
